import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/micoapp',
        name: 'micoapplist',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icp/micoapp')
    },
    {
        path: '/micoapp/create',
        name: 'micoappcreate',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icp/micoapp_create')
    },
    {
        path: '/micoapp/:appid',
        name: 'micoappicp',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icp/micoapp_info')
    },
    {
        path: '/micoapp/:appid/menu',
        name: 'micoappmenu',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icp/micoapp_menu')
    },
    {
        path: '/micoapp/:appid/charge',
        name: 'micoappcharge',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icp/micoapp_charge')
    },
    {
        path: '/micoapp/:appid/point',
        name: 'micoapppoint',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icp/micoapp_point')
    },
    {
        path: '/micoapp/:appid/register',
        name: 'micoappregister',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icp/micoapp_register')
    },
    {
        path: '/micoapp/:appid/sale',
        name: 'micoappsale',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icp/micoapp_sale')
    },
    {
        path: '/micoapp/:appid/home',
        name: 'micoapphome',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icp/micoapp_home')
    }

]
